import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface Image {
    src: string;
    alt: string;
}

interface GalleryProps {
    folder: 'CGI' | 'Retouching';
}

const Gallery: React.FC<GalleryProps> = ({ folder }) => {
    const [images, setImages] = useState<Image[]>([]);
    const [show, setShow] = useState(false);
    const [selectedImg, setSelectedImg] = useState(0);

    useEffect(() => {
        async function fetchImages() {
            const response = await fetch(`/imgs/images.json`);
            const data = await response.json();
            const loadedImages: Image[] = data[folder].map((filename: string) => ({
                src: `/imgs/${folder}/${filename}`,
                alt: filename
            }));
            setImages(loadedImages);
        }

        fetchImages();
    }, [folder]);

    const handleShow = (index: number) => {
        setSelectedImg(index);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const navigateImage = (step: number) => {
        setSelectedImg(prev => (prev + step + images.length) % images.length);
    };

    return (
        <>
            <div className="d-flex flex-column align-items-center">
                {images.map((img, index) => (
                    <img
                        key={img.alt}
                        src={img.src}
                        alt={img.alt}
                        className="img-fluid my-2"
                        onClick={() => handleShow(index)}
                    />
                ))}
            </div>


        </>
    );
};

export default Gallery;
