import React, { useEffect } from 'react';

interface AutoDownloadFileProps {
    path: string;  // Add a prop for the download path
}

const AutoDownloadFile: React.FC<AutoDownloadFileProps> = ({ path }) => {
    useEffect(() => {
        const link = document.createElement('a');
        link.href = path;  // Use the path prop for the href
        link.setAttribute('download', '');  // Assuming you want to use the server-suggested filename
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
    }, [path]);  // Include path in the dependency array to re-run the effect if path changes

    return (
        <div>Downloading file...</div>
    );
};

export default AutoDownloadFile;
