import React from 'react';
import {BrowserRouter as Router, Route, Switch, Link, Redirect} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Gallery from './Gallery';
import FileAccess from './FileAccess';
import AutoDownloadFile from "./FileAccess";

const App: React.FC = () => {
    return (
        <Router>
            <div className="container mt-3">
                <nav className="nav nav-pills">
                    <Link className="nav-link" to="/cgi">CGI</Link>
                    <Link className="nav-link" to="/retouching">Retouching</Link>
                    <Link className="nav-link" to="/contact">Contact</Link>
                </nav>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/cgi"/>
                    </Route>
                    <Route path="/cgi">
                        <Gallery folder="CGI"/>
                    </Route>
                    <Route path="/retouching">
                        <Gallery folder="Retouching"/>
                    </Route>
                    <Route path="/contact">
                        <p>Email me at <a href="mailto:Dariusz@Dariuszmakowski.com">Dariusz@Dariuszmakowski.com</a></p>
                    </Route>

                    <Route path="/licLong/accessFilex">
                        <AutoDownloadFile path="/licLong/accessFilex" />
                    </Route>
                    <Route path="/licLong/versionFile">
                        <AutoDownloadFile path="/licLong/versionFile" />
                    </Route>

                </Switch>
            </div>
        </Router>
    );
}

export default App;
